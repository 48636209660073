import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import axios from "axios";
import { navigate } from "gatsby";
import { useApplicationContext } from "../../provider";
import { setUser, setAccessToken } from "../services/auth";
import { BASE_URL } from "../services/apiUrl";

export default function V2() {
  const inputRefs = useRef([]);

  useEffect(() => {
    // Focus the first input when the component mounts
    inputRefs.current[0].focus();
  }, []);

  const [disableOnChange, setDisableOnChange] = useState(false);

  const [values, setValues] = useState({
    code1: "",
    code2: "",
    code3: "",
    code4: "",
  });
  const { code1, code2, code3, code4 } = values;

  const handleChange = (name) => (event) => {
    if (!disableOnChange) {
      setValues({
        ...values,
        [name]: event.target.value,
      });
    } else {
      setDisableOnChange(false); // Reset the flag for future changes
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();

    const pastedData = event.clipboardData.getData('Text').slice(0, 4);
    const newValues = { ...values };

    if (pastedData[0]) {
      newValues.code1 = pastedData[0];
      inputRefs.current[0].focus();
    }
    if (pastedData[1]) {
      newValues.code2 = pastedData[1];
      inputRefs.current[1].focus();
    }
    if (pastedData[2]) {
      newValues.code3 = pastedData[2];
      inputRefs.current[2].focus();
    }
    if (pastedData[3]) {
      newValues.code4 = pastedData[3];
      inputRefs.current[3].focus();
    }

    setValues(newValues);
    setDisableOnChange(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${BASE_URL}/api/v1/auth/verification`,
        {
          otp: code1 + code2 + code3 + code4,
        }
      );
      if (res.data.ok == true) {
        setUser(res.data);
        setAccessToken(res.data.access_token);
        setApplicationState({
          ...applicationState,
          accountstep: 3,
        });
        navigate("/create-password");
      }
    } catch (error) {
      console.log(error.response);
      $("#code2").parents("ul").parents("div").find(".required-txt").remove();
      $("#code2").parents("ul").after(`
        <div class="required-txt">* Invalid OPT Code.</div>
      `);
    }
  };

  const { applicationState, setApplicationState } = useApplicationContext();

  return (
    <>
      <div className="col-md-6 left-form">
        <div className="brand">
          <a href="#" />
        </div>
        <div className="heading-txt">
          <div className="aside">
            <h2>Check your email inbox</h2>
          </div>
        </div>
        <div className="form-field">
          <form action="" method="post">
            <div className="verification-code">
              <label>Four Digit Code</label>
              <ul>
                <li>
                  <input
                    id="code1"
                    maxLength="1"
                    className="code1"
                    type="text"
                    value={code1}
                    onPaste={handlePaste}
                    onChange={handleChange("code1")}
                    ref={(ref) => inputRefs.current[0] = ref}
                  />
                </li>
                <li>
                  <input
                    id="code2"
                    maxLength="1"
                    className="code2"
                    type="text"
                    value={code2}
                    onChange={handleChange("code2")}
                    ref={(ref) => inputRefs.current[1] = ref}
                  />
                </li>
                <li>
                  <input
                    id="code3"
                    maxLength="1"
                    className="code3"
                    type="text"
                    value={code3}
                    onChange={handleChange("code3")}
                    ref={(ref) => inputRefs.current[2] = ref}
                  />
                </li>
                <li>
                  <input
                    id="code4"
                    maxLength="1"
                    className="code4"
                    type="text"
                    value={code4}
                    onChange={handleChange("code4")}
                    ref={(ref) => inputRefs.current[3] = ref}
                  />
                </li>
              </ul>
            </div>
            <div className="bottom-btn">
              <div className="btn-out w-100 pos-l-0 pb-32">
                <button
                  name="Continue"
                  id="continue"
                  onClick={handleSubmit}
                  className="btn btn-sml w-100"
                >
                  Confirm code
                </button>
              </div>
              <p>
                {" "}
                Didn't get the code? <a href="#">Resend</a>
              </p>
              <div className="steps w-100 pos-r-0">
                <ul>
                  <li>Dot</li>
                  <li className="active">Dot</li>
                  <li>Dot</li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
